import React from 'react';
import './message.css';

const Message = ({ message }) => {
  return (
    <div
      className={`message ${message.role}`}
      dangerouslySetInnerHTML={{ __html: message.content }}
    />
  );
};

export default Message;
