import { useState, useEffect } from 'react';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';

export const API_BASE_URL = "https://server.wordworks.ai"; // https://server.wordworks.ai | http://localhost:4000
export const OPENAI_API_KEY = "your_openai_api_key_here";

export function useAPI() {

    const [user, setUser] = useState({});
    const [remainingRequests, setRemainingRequests] = useState("5");
    const [isAPIReady, setIsAPIReady] = useState(false);
    const [callback, setCallback] = useState(null);
    const [aiPersona, setAiPersona] = useState("amelia");
    const [instructionSet, setInstructionSet] = useState("indicators");
    const [model, setModel] = useState("gpt-3.5-turbo");
    const [jwtToken, setJWTToken] = useState(null);

    useEffect(() => {
        const token = Cookies.get('jwt');
        if (token) {
            handleSignOut();
        }
    }, []);

    useEffect(() => {
        console.log(callback);
        if (user && callback) {
            callback(user);
        }
    }, [user, callback]);

    const handleSignOut = async () => {
        setUser({});
        document.getElementById("signIn-button").hidden = false;
        document.getElementById("signOut-button").hidden = true;

        setRemainingRequests("5");

        Cookies.remove('jwt');
    };

    const handleGoogleCallbackResponse = async (response) => {
        const idToken = response.credential;

        const endpoint = "/api/auth/google";
        const url = `${API_BASE_URL}${endpoint}`;

        const authResponse = await fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ idToken }),
        });

        if (authResponse.ok) {
            const { token } = await authResponse.json();
            Cookies.set("jwt", token, { expires: 1 / 24, secure: true, sameSite: "strict" });

            setJWTToken(token);

            var userObject = jwt_decode(token);
            setUser(userObject);
            console.log("Current User: " + JSON.stringify(userObject))

            document.getElementById("signIn-button").hidden = true;
            document.getElementById("signOut-button").hidden = false;

            setIsAPIReady(true);

        } else {
            console.error("**Authentication failed.");
            const errorText = await authResponse.text();
            console.log(`Response body: ${errorText}`);

            throw (new Error(errorText));
        }
    };

    const initializeGoogleSignIn = (onUserUpdate, retryCount = 0) => {
        if (retryCount > 5) { // Maximum 5 retries
            console.error('Failed to initialize Google Sign-In after 5 retries');
            return;
        }

        if (window.google && window.google.accounts && window.google.accounts.id) {
            console.log("Callback set to: " + onUserUpdate);
            setCallback(onUserUpdate);

            document.getElementById("signIn-button").hidden = false;
            document.getElementById("signOut-button").hidden = true;

            /* global google */
            google.accounts.id.initialize({
                client_id: "956481822614-gftao97enl1thmco1cl8vf1uja47v4tm.apps.googleusercontent.com",
                callback: handleGoogleCallbackResponse
            });

            google.accounts.id.renderButton(
                document.getElementById("signIn-button"),
                { theme: "outline", size: "large" }
            );
        } else {
            setTimeout(() => initializeGoogleSignIn(onUserUpdate, retryCount + 1), 1000); // Retry after 1 second
        }
    };

    const sendRequest = async (endpoint, body) => {
        try {
            console.log("Calling Endpoint: " + endpoint);

            const url = `${API_BASE_URL}${endpoint}`;

            const token = Cookies.get('jwt');

            // If the JWT token is not present, exit early
            if (!token) {
                console.error('JWT token not found');
                return "Please <u><strong>log in</span></strong></u></u> to start";
            } else if (token !== jwtToken) {
                console.error('User JWT token does not match Cookie.  Disconnecting...');
                return "You are signed in on a different tab.  Please log out and log back in if you would like to continue.";
            }

            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(body),
            });

            // Check the response for your custom header
            const remainingRequestsFromHeader = response.headers.get('X-Remaining-Requests');

            // Update the state if the header exists
            if (remainingRequestsFromHeader) {
                //setRemainingRequests(parseInt(remainingRequestsFromHeader));
                console.log("Remaining Requests from Header: " + remainingRequestsFromHeader);
            }

            if (!response.ok) {
                console.error("Error calling the API:", response.statusText);
                throw new Error(response.statusText);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            const errorMessage = "Error in API call: " + error.message;
            console.error(errorMessage);
            return errorMessage;
        }
    };

    const sendQueryToAPI = async (chatHistory) => {
        console.log("-- sendQueryToAPI Called --")
        const body = {
            model: model,
            aiPersona: aiPersona,
            instructionSet: instructionSet,
            prompt: chatHistory,
        };

        try {
            const endpoint = "/api/chat/completion";
            const data = await sendRequest(endpoint, body);

            // Depending on the structure of the response data, you may need to adjust this line
            // Here it's assumed that `data` is a JSON object with a `choices` property that is an array,
            // and the first item of that array has a `message` property which in turn has a `content` property.
            return data.choices[0].message.content;
        } catch (error) {
            const errorMessage = "Error in API call: " + error.message;
            console.error(errorMessage);
            return errorMessage;
        }
    };

    const generateImage = async (imagePrompt) => {
        console.log("-- generateImage Called --")
        const body = {
            prompt: imagePrompt
        };
    
        try {
            const endpoint = "/api/images/generations";
            const responseUrl = await sendRequest(endpoint, body);
    
            setRemainingRequests(remainingRequests - 1);
            return responseUrl;
        } catch (error) {
            console.error("Error in API call:", error);
            throw error;
        }
    };

    const sendQueryToLangChain = async (chatHistory) => {
        console.log("-- sendQueryToLangChain Called --")

        const body = {
            model: model,
            aiPersona: aiPersona,
            instructionSet: instructionSet,
            prompt: chatHistory,
        };
    
        try {
            const endpoint = "/api/lc/agent";
            const data = await sendRequest(endpoint, body);
            return data.text;
        } catch (error) {
            const errorMessage = "Error in API call: " + error.message;
            console.error(errorMessage);
            return errorMessage;
        }
    };
    
    const sendQueryToGenerateStory = async (chatHistory) => {
        console.log("-- sendQueryToGenerateStory Called --")

        const body = {
            model: model,
            aiPersona: aiPersona,
            instructionSet: instructionSet,
            prompt: chatHistory,
        };
    
        try {
            const endpoint = "/api/lc/story";
            const data = await sendRequest(endpoint, body);
            return data.text;
        } catch (error) {
            const errorMessage = "Error in API call: " + error.message;
            console.error(errorMessage);
            return errorMessage;
        }
    };
    
    const sendQueryToSaveStory = async (story, imagePrompt, imageUrl ) => {
        console.log("-- sendQueryToGenerateStory Called --")

        const body = {
            story: story,
            imagePrompt: imagePrompt,
            imageUrl: imageUrl
        };
    
        try {
            const endpoint = "/api/stories";
            const data = await sendRequest(endpoint, body);
            return data;
        } catch (error) {
            const errorMessage = "Error in API call: " + error.message;
            console.error(errorMessage);
            return errorMessage;
        }
    };

    const sendQueryToEndpoint = async (chatHistory, endpoint) => {
        console.log("-- sendQueryToEndpoint Called --")
        const body = {
            model: model,
            aiPersona: aiPersona,
            instructionSet: instructionSet,
            prompt: chatHistory,
        };
    
        try {
            const data = await sendRequest(endpoint, body);
            return data.text;
        } catch (error) {
            const errorMessage = "Error in API call: " + error.message;
            console.error(errorMessage);
            return errorMessage;
        }
    };
    
    return {
        isAPIReady,
        user,
        remainingRequests, setRemainingRequests,
        model, setModel,
        aiPersona, setAiPersona,
        instructionSet, setInstructionSet,
        initializeGoogleSignIn,
        handleGoogleCallbackResponse,
        handleSignOut,
        sendQueryToAPI,
        sendQueryToEndpoint,
        sendQueryToLangChain,
        sendQueryToGenerateStory,
        sendQueryToSaveStory,
        generateImage,
    };
}
