import React, { useState, useEffect } from 'react';
import './App.css';
import Toolbar from './components/toolbar';
import InputForm from './components/inputform';
import ChatContainer from './components/chatContainer';
import './components/message';

import { useAppContext } from './AppContext';

function App() {

  const {
    user,
    sendQueryToAPI,
    sendQueryToEndpoint,
    sendQueryToLangChain,
    sendQueryToGenerateStory,
    sendQueryToSaveStory,
    generateImage,
    remainingRequests, setRemainingRequests,
    socketData,
  } = useAppContext();

  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isStoryCreated, setIsStoryCreated] = useState(false);
  const [hasStartedStory, setHasStartedStory] = useState(false);
  const [isImageCreated, setIsImageCreated] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const [story, setStory] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imagePrompt, setImagePrompt] = useState("");

  // Set the initial greeting
  const initialGreeting = `
  <p>Welcome to <em>WordWorks AI</em>.<br></p>
  <p>Click <a href="https://wordworks.ai/wp-login.php" target="_blank" style="color: #FF0000; font-size: 20px; font-weight: bold; text-decoration: underline;">Register</a> 
  and then <span style="color: #FFFFFF; font-size: 20px; font-weight: bold;">sign in</span> to get started.</p>
    <ul>
        <li>Click <span style="color: #FFFFFF; font-size: 16px; font-weight: bold;">"Start the Adventure"</span> to begin!</li>
        <li>When you're ready, click "Tell the Story" to create your unique story.</li>
        <li>A picture illustrating your story will also be created.</li>
    </ul>
  <p style="font-size: 12px; color: #999999;">*Please note, you are limited to 5 requests per session and you shouldn't include any sensitive data in your requests</p>
`;


  useEffect(() => {
    let isMounted = true;

    async function initialAPICall() {
      if (!isMounted) return;

      setIsLoading(true);

      const aiResponse = initialGreeting;
      setMessages((prevMessages) => [
        { role: "ai", content: aiResponse },
      ]);

      setIsLoading(false);
    }

    initialAPICall();

    return () => {
      isMounted = false;
    };
  }, [initialGreeting]);


  const handleStartAdventure = async () => {
    console.log("clicked the Start Adventure Button!")

    setIsLoading(true);

    const prompt1 = "Introduce yourself and ask some brainstorming questions to start the story?";

    // Prepare the chat history for the first API call
    const chatHistory1 = [
      ...messages.slice(-30, -1).map((message) => {
        return {
          role: message.role === 'ai' ? 'assistant' : 'user',
          content: message.content,
        };
      }),
      { role: "user", content: prompt1 },
    ];

    try {

      // Start new API response from the second call to the messages array
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "ai", content: "" },
      ]);

      const apiResponse1 = await sendQueryToLangChain(chatHistory1);

      console.log("Story Prompt: \n" + apiResponse1);

      setHasStartedStory(true);
      setIsLoading(false);
    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "error", content: error.message },
      ]);

      setIsLoading(false);
    }
  };

  const handleCreateStory = async () => {
    // Access the entire chat message log
    console.log("clicked the Create Story Button!");

    setIsLoading(true);

    // Prepare the chat history for the first API call
    const chatHistory = [
      ...messages.slice(-30, -1).map((message) => {
        return {
          role: message.role === 'ai' ? 'assistant' : 'user',
          content: message.content,
        };
      }),
    ];

    try {

      if (story) {

        callAPI("continue");
        setIsLoading(false);
      }
      else {
        // Start new API response from the second call to the messages array
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: "ai", content: "" },
        ]);

        const apiResponse1 = await sendQueryToGenerateStory(chatHistory);
        setStory(apiResponse1);

        console.log("Story Created: \n" + apiResponse1)
        
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: "ai", content: apiResponse1 },
        ]);

        setMessages((prevMessages) => [
          ...prevMessages,
          { role: "ai", content: "Image Prompt: " },
        ]);


        const imagePrompt = await sendQueryToEndpoint(apiResponse1, "/api/lc/imageprompt")
        setImagePrompt(imagePrompt);
        console.log("Image Prompt: " + imagePrompt);

        const imageResponse2 = await generateImage(imagePrompt);
        setImageUrl(imageResponse2);

        const htmlResponse = `<img src="${imageResponse2}">`;
        // Add API response from the second call to the messages array
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: "ai", content: htmlResponse },
        ]);

        setIsLoading(false);
        setIsStoryCreated(true);
      }
    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "error", content: error.message },
      ]);

      setIsLoading(false);
    }
  };

  const handleSaveStory = async () => {

    setIsLoading(true);

    try {
      const apiResponse1 = await sendQueryToSaveStory(story, imagePrompt, imageUrl);

      console.log("Response: \n" + apiResponse1);


      // Add API response from the second call to the messages array
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "ai", content: apiResponse1 },
      ]);

      setIsLoading(false);
      setIsSaved(true);

    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "error", content: error.message },
      ]);

      setIsLoading(false);
    }
  };

  const handleCreateImage = async () => {
    // Access the entire chat message log
    console.log("clicked the Create Image Button!");

    setIsLoading(true);
    setIsImageCreated(true);

    const prompt1 = "Based on our the provided story write a 50 word text based prompt describing an image inspired by the story.";

    const requestContent = prompt1 + story;

    console.log("Request: \n" + requestContent);


    // Prepare the chat history for the first API call
    const chatHistory1 = [
      { role: "user", content: requestContent },
    ];

    try {
      const apiResponse1 = await sendQueryToAPI(chatHistory1);

      console.log("Response: \n" + apiResponse1);

      const prompt2 = apiResponse1;

      const apiResponse2 = await callGenerateImage(prompt2);

      // Add API response from the second call to the messages array
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "ai", content: apiResponse2 },
        { role: "ai", content: prompt2 },
      ]);


      setIsLoading(false);

    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "error", content: error.message },
      ]);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("Remaining Requests: " + remainingRequests);
  }, [remainingRequests]);

  useEffect(() => {
    console.log("User Changed: " + JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    if (socketData) {
      setMessages((prevMessages) => {
        // Deep copy the previous messages array
        const newMessages = JSON.parse(JSON.stringify(prevMessages));

        // Check if the last message was from the 'ai'
        if (newMessages.length > 0 && newMessages[newMessages.length - 1].role === 'ai') {
          // If it was, append the new data to the last 'ai' message
          newMessages[newMessages.length - 1].content += socketData;
        } else {
          // Otherwise, add a new 'ai' message
          newMessages.push({ role: 'ai', content: socketData });
        }

        // Return the new messages array to update the state
        return newMessages;
      });
    }
  }, [socketData]);

  useEffect(() => {
    console.log("Story Created: " + isStoryCreated);
  }, [isStoryCreated]);

  useEffect(() => {
    console.log("Story Saved: " + isSaved);
  }, [isSaved]);

  useEffect(() => {
    // Scroll to the bottom on a new message.
    //window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }, [messages]);

  async function callGenerateImage(query) {
    setIsLoading(true);
    console.log("Calling API");

    // Add user input to the messages array
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: 'user', content: query },
    ]);

    const apiResponse = await generateImage(query);

    // Add API response from the second call to the messages array
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "ai", content: apiResponse },
    ]);

    setIsLoading(false);
  }

  async function callAPI(query) {
    setIsLoading(true);
    console.log("Calling API");

    // Add user input to the messages array
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: 'user', content: query },
    ]);

    // Prepare the chat history for the API call
    const chatHistory = [
      ...messages.slice(-25).map((message) => {
        return {
          role: message.role === 'ai' ? 'assistant' : 'user',
          content: message.content,
        };
      }),
      { role: "user", content: query },
    ];

    const aiResponse = await sendQueryToLangChain(chatHistory);

    console.log(aiResponse);

    if (remainingRequests) {
      setRemainingRequests( remainingRequests - 1);
    }

    setIsLoading(false);
  }

  return (
    <div className="App">
      <Toolbar
        remainingRequests={remainingRequests}
        onButton1={ handleStartAdventure}
        disableButton1={hasStartedStory || isLoading }
        onButton2={handleCreateStory}
        disableButton2={ !hasStartedStory || isStoryCreated || isLoading }
        onButton3={handleSaveStory}
        disableButton3={ !isStoryCreated || isImageCreated || isSaved || isLoading }
      />
      <div>
        WebSocket data: {JSON.stringify(socketData)}
      </div>
      <header className="App-header">
        <ChatContainer messages={messages} isLoading={isLoading} />
        <InputForm onSubmit={callAPI} disabled={ !user.wpId || ( remainingRequests === 0)} />
      </header>
    </div>
  );
}

export default App;