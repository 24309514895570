import React, { useState, useRef, useEffect } from 'react';
import './inputform.css';

const InputForm = ({ onSubmit, disabled }) => {
  const [inputValue, setInputValue] = useState('');
  const textAreaRef = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(inputValue);
    setInputValue('');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (event.shiftKey) {
        setInputValue(inputValue + '\n');
      } else {
        handleSubmit(event);
      }
    }
  };

  const handleInput = (event) => {
    setInputValue(event.target.value);
  };

  const handleScrollButtonClick = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '20px';
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
    }
  }, [inputValue]);

  return (
    <div className="input-form-wrapper">
      <form className="input-form" onSubmit={handleSubmit}>
        <div className="input-form-scroll">
          <button type="button" onClick={handleScrollButtonClick} disabled={false} title="Scroll to the bottom">
            &#8595;
          </button>
        </div>
        <textarea
          ref={textAreaRef}
          value={inputValue}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          className="input-text"
          placeholder="Type your message..."
          disabled={disabled}
        />
        <div className="send-button-wrapper">
          <button type="submit" className="send-button" disabled={disabled} >💬</button>
        </div>
        <div className="terms-link-wrapper">
          <a href="https://wordworks.ai/terms-of-service/" className="terms-link" target="_blank" rel="noopener noreferrer">Terms of Service</a>
          <span className="terms-link-separator"> | </span>
          <a href="https://wordworks.ai/privacy-policy/" className="privacy-link" target="_blank" rel="noopener noreferrer">Privacy Statement</a>
        </div>
      </form>
    </div>
  );
};

export default InputForm;
