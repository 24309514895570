import React from 'react';
import Message from './message';
import './chatContainer.css';

const ChatContainer = ({ messages, isLoading }) => {
  return (
    <div className="chat-container">
      {messages.map((message, index) => (
        <Message key={index} message={message} />
      ))}
      {isLoading && (
        <div className="message loading">
          💬 <i>thinking</i><span className="dot dot-1">.</span>
          <span className="dot dot-2">.</span>
          <span className="dot dot-3">.</span>
        </div>
      )}
    </div>
  );
};


export default ChatContainer;
