import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAPI } from './hooks/api';
import { useWebSocket } from './hooks/websocket';
import Cookies from 'js-cookie';

const AppContext = createContext();

export function useAppContext() {
  return useContext(AppContext);
}

export function AppProvider({ children }) {
  const {
    isAPIReady,
    user,
    remainingRequests, setRemainingRequests,
    maxRequests,
    model, setModel,
    aiPersona, setAiPersona,
    instructionSet, setInstructionSet,
    initializeGoogleSignIn,
    handleGoogleCallbackResponse,
    handleSignOut,
    sendQueryToAPI,
    sendQueryToEndpoint,
    sendQueryToLangChain,
    sendQueryToGenerateStory,
    sendQueryToSaveStory,
    generateImage,
    useRequest,
    setSessionValue,
    getSessionValue,
    callProtectedRoute,
    decrementRemainingRequests,
  } = useAPI();

  const [socketData, setSocketData] = useState(null);
  const { socket, sendSocketMessage } = useWebSocket(user ? user.token : null, setSocketData);


  useEffect(() => {
    if (isAPIReady && user) {
      const jwt = Cookies.get('jwt');
      console.log(jwt);

      // send 'authenticate' event with token to the
      console.log('Socket is ready');
      sendSocketMessage('authenticate', jwt);
    }
  }, [isAPIReady, user, socket, sendSocketMessage]);

  const value = {
    socket, socketData, sendSocketMessage, 
    isAPIReady,
    user,
    remainingRequests, setRemainingRequests,
    maxRequests, 
    model, setModel,
    aiPersona, setAiPersona,
    instructionSet, setInstructionSet,
    initializeGoogleSignIn,
    handleGoogleCallbackResponse,
    handleSignOut,
    sendQueryToAPI,
    sendQueryToEndpoint,
    sendQueryToLangChain,
    sendQueryToGenerateStory,
    sendQueryToSaveStory,
    generateImage,
    useRequest,
    setSessionValue,
    getSessionValue,
    callProtectedRoute,
    decrementRemainingRequests,
  };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  )
}