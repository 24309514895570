// websocket.js
import { useEffect, useState, useCallback } from 'react';
import { API_BASE_URL }  from './api';
import io from 'socket.io-client';

export const useWebSocket = (token, onDataCallback) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io(API_BASE_URL, { 
        query: { token } 
    });

    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Connected to server');
    });

    newSocket.on('message', (message) => {
      console.log('Received message:', message);
    });

    newSocket.on('data', (data) => {
        if (typeof onDataCallback === 'function') {
            onDataCallback(data);
        }
      });

    newSocket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    return () => newSocket.close();
  }, [token, onDataCallback]);

  const sendSocketMessage = useCallback((event, message) => {
    if (socket) {
      console.log("Emit Event: " + event + " - " + message);
      socket.emit(event, message);
    }
  }, [socket]);

  return { socket, sendSocketMessage };
};