import React, { useState, useEffect } from 'react';
import './toolbar.css';
import logo from '../images/ww.logo.png';
import { useAppContext } from '../AppContext';

const Toolbar = ({ remainingRequests, onButton1, disableButton1, onButton2, disableButton2, onButton3, disableButton3 }) => {

  const {
    initializeGoogleSignIn,
    handleSignOut,
    isAPIReady,
    user,
    setAiPersona,
  } = useAppContext();

  const remainingRequestsClass = remainingRequests === 0 ? "remaining-requests no-requests" : "remaining-requests";

  const [userName, setUserName] = useState('');
  const [userSub, setUserSub] = useState('');
  const [userPicture, setUserPicture] = useState('');
  const [disableButtons, setDisableButtons] = useState(true);
  const [dropdownValue, setDropdownValue] = useState('amelia');

  const handleDropdownChange = (e) => {
    setDropdownValue(e.target.value);
    console.log(e.target.value);
    setAiPersona(e.target.value);
  };

  const onSignOut = (e) => {
    document.getElementById("user-name").hidden = true;
    document.getElementById("user-image").hidden = true;
    document.getElementById("user-sub").hidden = true;
    //document.getElementById("dropdown").disabled = false;
    handleSignOut(e);
  };

  const internalOnButton1 = (e) => {
    if (onButton1) {
      onButton1(e);
    }
  };

  const internalOnButton2 = (e) => {
    if (onButton2) {
      onButton2(e);
    }
  };

  const internalOnButton3 = (e) => {
    if (onButton3) {
      onButton3(e);
    }
  };

  // This is the method you will pass into initializeGoogleSignIn
  const onUserUpdate = () => {
    document.getElementById("user-name").hidden = true;
    document.getElementById("user-image").hidden = true;
    document.getElementById("user-sub").hidden = true;
    //document.getElementById("dropdown").disabled = false;
  };

  useEffect(() => {
    if (!isAPIReady) {
      // Pass the onTokenUpdate method as a callback to initializeGoogleSignIn
      initializeGoogleSignIn(onUserUpdate);
    }
  }, [isAPIReady, initializeGoogleSignIn]);


  useEffect(() => {
    if (user.displayName) {
      setUserName(user.displayName);
      setUserPicture(user.pictureURL);

      // Matches the content within the quotes which represents the subscription level from WordPress in the App.
      const regex = /"(.*?)"/; 

      const match = regex.exec(user.capabilities);
      if (match && match[1]) {
        const subLevel = match[1];

        switch (subLevel)
        {
          case 'administrator' :
            setUserSub("👑");
            break;
          case 'contributor' :
            setUserSub("⭐️");
            break;
          case 'subscriber' :
            setUserSub("👤");
            break;
          default : 
          setUserSub("👥");
        }
      }

      document.getElementById("user-name").hidden = false;
      document.getElementById("user-image").hidden = false;
      document.getElementById("user-sub").hidden = false;
      //document.getElementById("dropdown").disabled = true;
    }

    if (document.getElementById("signIn-button").hidden) {
      setDisableButtons(false);
    }

  }, [user]);

  return (
    <div className="toolbar">
      <div className="toolbar-logo">
        <a href="https://www.wordworks.ai/">
          <img src={logo} alt="WordWorks AI Logo" />
        </a>
        <div className="toolbar-title">
          <a href="https://www.wordworks.ai/">WordWorks AI</a>
        </div>
      </div>
      <div className="toolbar-buttons">
        <select id="dropdown" value={dropdownValue} onChange={handleDropdownChange}>
          <option value="amelia">Amelia</option>
          <option value="julian">Julian</option>
          <option value="edgar">Edgar</option>
        </select>
        <button id="button1" onClick={internalOnButton1} disabled={disableButtons || disableButton1}>Start the Adventure</button>
        <button id="button2" onClick={internalOnButton2} disabled={disableButtons || disableButton2} >Tell the Story</button>
        <button id="button3" onClick={internalOnButton3} disabled={disableButtons || disableButton3 || (userSub === "👤") || (userSub === "👥")}>Save the Story</button>
      </div>
      <h1 hidden="true" id="user-sub" className="user-sub" >{userSub}</h1>
      <div hidden="true" id="req-box"className={remainingRequestsClass} >
        Remaining Requests: {remainingRequests}
        <h1 hidden="true" id="user-name" className="user-name" >{userName}</h1>
      </div>
      <div id="signIn-button"></div>
      <div className="user-box">
        <div className="user-info">
          <img hidden="true" id="user-image" className="user-image" src={userPicture} alt="User Img" ></img>
          <button hidden="true" id="signOut-button" className="signOut-button" onClick={(e) => onSignOut(e)}>Sign Out</button>
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
